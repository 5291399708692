
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import { number } from "yup/lib/locale";
import { todayDate } from "@/core/data/events";
import moment from "moment";

export default defineComponent({
  name: "add-company-modal",
  directives: { mask },
  components: {},
  // methods: {
  //   async onJournalType(journal_type_id) {
  //     const store = useStore();

  //     const jn_type_data = ref([]);
  //     const ac_m_data = ref([]);

  //     const formRef = ref<null | HTMLFormElement>(null);
  //     const addCompanyModalRef = ref<null | HTMLElement>(null);
  //     const loading = ref<boolean>(false);
  //     const router = useRouter();
  //     await store
  //       .dispatch(ActionsFi.CUST_GET_TRANSACTION_NO, journal_type_id)
  //       .then(({ data }) => {
  //         if (data) {
  //           console.log(JSON.stringify(data));
  //         }
  //       })
  //       .catch(({ response }) => {
  //         console.log(response);
  //       });
  //   },
  // },
  setup() {
    const store = useStore();

    const jn_type_data = ref([]);
    const account_type_data = ref([]);
    const account_group_data = ref([]);
    const ac_m_data = ref([]);
    // const journalNumber = ref([]);
    const references = ref([
      { reference_name: "test", reference_id: 1 },
      { reference_name: "new", reference_id: 2 },
      { reference_name: "last", reference_id: 3 },
    ]);

    const formRef = ref<null | HTMLFormElement>(null);
    const addCompanyModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    const formData = ref({
      tableData: ref<Array<WIjournalData>>([]),
      debit_am_tot: ref<string>("0.00"),
      credit_amtot: ref<string>("0.00"),
      journal_date: moment().format("YYYY-MM-DD"),
      journal_date_type: ref<string>(""),
      // reference_type: ref<string>(""),
      journalNumber: ref<string>(""),
      account_type: ref<string>(""),
      account_group: ref<string>(""),
      narration: ref<string>(""),
    });

    getJournalTypes();
    getAccountTypes();
    interface WIjournalData {
      ac_name: "";
      debit_am: "";
      credit_am: "";
      delete_row: number;
    }
    // var tableData = ref<Array<WIjournalData>>([]);

    formData.value["tableData"].push({
      ac_name: "",
      debit_am: "",
      credit_am: "",
      delete_row: 0,
    });

    const addNewRow = () => {
      formData.value["tableData"].push({
        ac_name: "",
        debit_am: "",
        credit_am: "",
        delete_row: 1,
      });
    };

    const deleteRow = (item) => {
      var idx = formData.value["tableData"].indexOf(item);
      if (idx > -1) {
        formData.value["tableData"].splice(idx, 1);
      }
      calculateDbTotal();
      calculateCdTotal();
    };

    const calculateDbTotal = (row:any = null) => {
      if (row && !/^\d*\.?\d*$/.test(row.debit_am)) {
        row.debit_am = row.debit_am.slice(0, -1);
        return;
      }
      var total = formData.value["tableData"].reduce(function (sum, entry) {
        var io = entry.debit_am == "" ? 0 : entry.debit_am;
        return sum + parseFloat(io.toString());
      }, 0);

      if (!isNaN(total)) {
        formData.value["debit_am_tot"] = parseFloat(
          total.toFixed(2)
        ).toString();
      } else {
        formData.value["debit_am_tot"] = "0.00";
      }
    };

    const calculateCdTotal = (row:any = null) => {
      if (row && !/^\d*\.?\d*$/.test(row.credit_am)) {
        row.credit_am = row.credit_am.slice(0, -1);
        return;
      }
      var total = formData.value["tableData"].reduce(function (sum, entry) {
        var io = entry.credit_am == "" ? 0 : entry.credit_am;
        return sum + parseFloat(io.toString());
      }, 0);

      if (!isNaN(total)) {
        formData.value["credit_amtot"] = parseFloat(
          total.toFixed(2)
        ).toString();
      } else {
        formData.value["credit_amtot"] = "0.00";
      }
    };

    const chkData = () => {
      console.log(formData.value["journal_date"]);
    };
    const checkCreditAmTot = (rule, value, callback) => {
      if (value != formData.value["debit_am_tot"]) {
        callback(
          new Error("Credit total amount should be same as credit total amount")
        );
      }
      if (value === formData.value["debit_am_tot"]) {
        if (formRef.value) {
          formRef.value.validateField("credit_amtot");
        }
      }
      callback();
    };
    const checkDebitAmTot = (rule, value, callback) => {
      console.log("check debit total", formData.value["credit_amtot"], value);
      if (value != formData.value["credit_amtot"]) {
        callback(
          new Error("Debit total amount should be same as credit total amount")
        );
        // return "Debit total amount should be same as credit total amount";
      }
      if (formData.value["credit_amtot"] === value) {
        if (formRef.value) {
          formRef.value.validateField("debit_am_tot");
        }
      }
      callback();
    };
    const rules = ref({
      journal_date_type: [
        {
          required: true,
          message: "Journal Type is required",
          trigger: "change",
        },
      ],
      journal_date: [
        {
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      // account_type: [
      //   {
      //     required: true,
      //     message: "Account Type is required",
      //     trigger: "change",
      //   },
      // ],
      // account_group: [
      //   {
      //     required: true,
      //     message: "Account Group is required",
      //     trigger: "change",
      //   },
      // ],
      // journalNumber: [
      //   {
      //     required: true,
      //     message: "Journal No is required",
      //     trigger: "change",
      //   },
      // ],
      // journalDate: [
      //   {
      //     required: true,
      //     message: "Journal date is required",
      //     trigger: "change",
      //   },
      // ],
      // debit_am_tot: [{ validator: checkDebitAmTot, trigger: "change" }],
      // credit_amtot: [{ validator: checkCreditAmTot, trigger: "change" }],
      // debit_am_tot: [
      //   {
      //     required: true,
      //     message: "Total is required",
      //   },
      // ],
    });
    // function onJournalType(journal_type_id) {
    //   console.log(journal_type_id);
    // }

    // const onJournalType = () => {
    //   console.log("test");
    // };
    // function onJournalType() {
    //   let test1 = "hi";
    //   console.log(test1);
    // }

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          if (
            formData.value["credit_amtot"] != formData.value["debit_am_tot"]
          ) {
            console.log("validation failed");
            Swal.fire({
              text: "Debit total ammount and Credit total ammount should be same.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            loading.value = false;
            return;
          }
          await setJournalData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };
    // const setJournalData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());
    //   var date = new Date(data.journal_date);
    //   var currDate =
    //     date.getFullYear() + "-" + date.getDate() + "-" + (date.getMonth() + 1);
    //   var particulars = data.tableData.map((n) => {
    //     const obj = {
    //       account_id: n.ac_name,
    //       transaction_nature_id: n.debit_am != "" ? 1 : 2,
    //       transaction_amount: n.debit_am != "" ? n.debit_am : n.credit_am,
    //       reference_table_id: "",
    //       reference_entity_id: "",
    //       reference_entity_name: "",
    //       reference_amount: "",
    //       reference_remarks: "",
    //     };
    //     return obj;
    //   });
    //   const db_data = {
    //     journal_entry_no: data.journal_entry_no,
    //     journal_entry_date: currDate,
    //     journal_type_id: data.journal_type_id,
    //     journal_entry_amount: data.credit_amtot,
    //     journal_entry_narration: data.narration,
    //     particulars: particulars,
    //     created_user_id: user.user_id,
    //     created_ip: "1.1.1.1",
    //     created_uagent: "asd adsads",
    //   };
    //   console.log(db_data);
    // };
    const setJournalData = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      var currDate = moment(data.journal_date).format("YYYY-MM-DD");
      var particulars = data.tableData.map((n, index) => {
        const obj = {          
          journal_sr_no : index+1,
          ledger_id : n.ac_name,
          transaction_nature_id: n.debit_am != "" ? 2 : 1,
          transaction_amount: n.debit_am != "" ? n.debit_am : n.credit_am,
        };
        return obj;
      });
      console.log('particulars', particulars);
      let validateProductList = particulars.filter((item) => {
        return (
          item.ledger_id != "" &&
          item.transaction_nature_id != "" &&
          item.transaction_amount != ""
        );
      });
      if (validateProductList.length < data.tableData.length) {
        console.log("validation failed");
        Swal.fire({
          text: "Please fill all perticular fields",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {        
        journal_id : 0,
        journal_date : currDate,
        journal_type_id : data.journal_date_type,
        journal_amount : data.credit_amtot,
        journal_remarks : data.narration,
        user_id : user.user_id,
        particulars : particulars
      };
      await store
        .dispatch(ActionsFi.CUST_ADD_JOURNAL_ENTRY, db_data)
        .then(({ data }) => {
          debugger;
          if (data) {
            console.log(data);
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Journal Entry has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addCompanyModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getLedgerData(query: string) {
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };

      await store
        .dispatch(ActionsFi.CUST_FA_SEARCH_LEDGER, values)
        .then(({ data }) => {
          ac_m_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });

      var req_data_JT = { records_per_page: 100, page: 1 };

      await store
        .dispatch(ActionsFi.CUST_GET_JOURNAL_TYPES_LIST, req_data_JT)
        .then(({ data }) => {
          // jn_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getJournalTypes() {
      await store
        .dispatch(ActionsFi.CUST_GET_JOURNAL_TYPE_LIST_DATA)
        .then(({ data }) => {
          jn_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAccountTypes() {
      let params = {
        group_id: 0,
      };
      await store
        .dispatch(ActionsFi.CUST_GET_OFFER_LIST_BY_INQUIRY_DATA, params)
        .then(({ data }) => {
          account_type_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getGroupData() {
      debugger;
      if (formData.value["account_type"] === "") {
        account_group_data.value = [];
        formData.value["account_group"] = "";
        return;
      }
      let params = {
        group_id: formData.value["account_type"],
      };
      await store
        .dispatch(ActionsFi.CUST_GET_OFFER_LIST_BY_INQUIRY_DATA, params)
        .then(({ data }) => {
          account_group_data.value = data;
          formData.value["account_group"] = "";
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getJournalNumber(journal_type_id) {
      console.log("date type::" + formData.value["journal_date_type"]);
      var req_data = { journal_type_id: journal_type_id };
      await store
        .dispatch(ActionsFi.CUST_GET_TRANSACTION_NO, req_data)
        .then(({ data }) => {
          if (data) {
            formData.value["journalNumber"] = data;
            console.log(JSON.stringify(data));
          }
          // else {
          //   loading.value = false;

          //   Swal.fire({
          //     text: "Sorry, looks like there are some errors detected, please try again.",
          //     icon: "error",
          //     buttonsStyling: false,
          //     confirmButtonText: "Ok, got it!",
          //     customClass: {
          //       confirmButton: "btn btn-primary",
          //     },
          //   });
          //   return false;
          // }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addCompanyModalRef,
      addNewRow,
      deleteRow,
      calculateDbTotal,
      calculateCdTotal,
      getJournalNumber,
      ac_m_data,
      jn_type_data,
      account_type_data,
      account_group_data,
      chkData,
      getGroupData,
      getLedgerData,
      references,
    };
  },
});
